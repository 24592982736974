import { useRef, useEffect, useState } from "react";
import { throttle } from "lodash";
import { usePreviousDistinct } from "react-use";

export const useVisibility = <Element extends HTMLElement>(
  offset = 0,
  throttleMilliseconds = 100
): [boolean, React.RefObject<Element>] => {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = useRef<Element>(null);
  const previousElement = usePreviousDistinct(currentElement);

  const onScroll = throttle(() => {
    if (!currentElement.current) {
      setIsVisible(true);
    } else {
      const sticky = currentElement.current.offsetTop;
      if (window.pageYOffset > sticky) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  }, throttleMilliseconds);
  useEffect(() => {
    document.addEventListener("scroll", onScroll, true);
    return () => document.removeEventListener("scroll", onScroll, true);
  });

  useEffect(() => {
    if (!previousElement && !!currentElement) {
      onScroll();
    }
  }, [currentElement]);

  return [isVisible, currentElement];
};
