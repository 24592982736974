import React, { useRef } from 'react'
import { Button } from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog'
import { useItemEditContext } from './bulkEdit';
import { sleep } from 'utils/useSleep';
import { useAssetComparisonContext } from 'Modules/Customers/HIPAARiskAssessment/Assets/BulkEditAssets';
import { useScenarioComparisonContext } from 'Modules/Customers/Risk/Comparison/TableComparison';

export const CreateBulkArchive = ({ items, type, modifyItem }) => {
  const itemEdit = type === 'Scenario' ? useScenarioComparisonContext() : (type === 'Assets' ? useAssetComparisonContext() : useItemEditContext()) 
  const isArchived = items.find((i) => i.id === itemEdit.checked[0])?.archived || false
  const dialog = useRef(null);

  const bulkArchiveItems = async() => {
    let criteriaMet: boolean | null = null;
    (dialog?.current as any).show?.({
      title: "Confirm Bulk Update",
      body: (
                <div>
                  <p>Are you sure you want to update all selected items ?</p>
                </div>
      ),
      actions: [
        Dialog.Action(
          "No",
          () => {
            criteriaMet = false;
            return "You clicked no";
          },
          "btn-danger",
        ),
        Dialog.Action(
          "Yes",
          () => {
            criteriaMet = true;
            return "You clicked yes";
          },
          "btn-primary",
        ),
      ],
    });
    while (criteriaMet === null) {
      await sleep(100);
    }
    
    if(criteriaMet){
      return await modifyItem({
        variables: {
          input: itemEdit.checked.map((item) => {
            return {
              id: item,
              archived: !isArchived,
            }
          }),
        },
      })
    }
    itemEdit.clear()
    return false;
  }
  return(
        <>
            <Dialog ref={dialog} />
            <Button
                className="Modify-text mr-2"
                variant="light"
                onClick={bulkArchiveItems}
            >
            {isArchived === false ? 'Archive' : 'Unarchive'}
            </Button>
        </>
  )
}