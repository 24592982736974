import React from 'react';
import { Button } from "react-bootstrap";
import { useMutation, gql } from "@apollo/client";
import {
  SetUserRoleInOrganizationMutation,
  SetUserRoleInOrganizationMutationVariables,
  EnableUserMutation,
  EnableUserMutationVariables,
  DisableUserMutation,
  DisableUserMutationVariables,
  UserRoles,
} from "API";
import {
  setUserRoleInOrganization,
  enableUser,
  disableUser,
} from "graphql/mutations";
import { usePermissions } from "common/Permissions";
import { sleep } from "utils/useSleep";

export const organizationUserAction = (props) => { 
  const { organization, refetchOrganizationusers } =        usePermissions();

  const [runEnableUser] = useMutation<
  EnableUserMutation,
  EnableUserMutationVariables
  >(gql(enableUser), {
    onCompleted: () => {
      sleep(500).then(refetchOrganizationusers);
    },
  });

  const [runDisableUser] = useMutation<
  DisableUserMutation,
  DisableUserMutationVariables
  >(gql(disableUser), {
    onCompleted: () => {
      sleep(500).then(refetchOrganizationusers);
    },
  });

  const [runSetUserRoleInOrganization] = useMutation<
  SetUserRoleInOrganizationMutation,
  SetUserRoleInOrganizationMutationVariables
  >(gql(setUserRoleInOrganization), {
    onCompleted: () => {
      sleep(500).then(refetchOrganizationusers);
    },
  });
  return (
        <span>
            {
            
                props?.value === 'admin'
                  ? <Button
                variant="link"
                onClick={() => {
                  runSetUserRoleInOrganization({
                    variables: {
                      input: [
                        {
                          organizationID: organization.id,
                          userID: props?.data?.ID,
                          userRole: UserRoles.write,
                        },
                      ],
                    },
                  });
                }}
                  >
                Make User
                    </Button>
                  : props?.value === 'write' || props?.value === 'read' 
                    ? <>
                <Button
            variant="link"
            onClick={() => {
              runSetUserRoleInOrganization({
                variables: {
                  input: [
                    {
                      organizationID: organization.id,
                      userID: props?.data?.ID,
                      userRole: UserRoles.admin,
                    },
                  ],
                },
              });
            }}
                >
            Make Admin ,
{" "}
                </Button>
        <Button
            variant="link"
            onClick={() => {
              runDisableUser({
                variables: {
                  input: [
                    {
                      organizationID: organization.id,
                      id: props?.data?.ID,
                    },
                  ],
                },
              });
            }}
        >
            {" "}
Disable User
        </Button>
                      </>
                    :                    props.value === 'inactive'
                      ? <Button
                variant="link"
                onClick={() => {
                  runEnableUser({
                    variables: {
                      input: [
                        {
                          organizationID: organization.id,
                          id: props?.data?.ID,
                        },
                      ],
                    },
                  });
                }}
                      >
                Enable User
                        </Button>
                      : ""
            }

        </span>
  );
};

