import React, { useMemo } from "react";
import ProductFruits from "react-product-fruits";
import { usePermissions } from "common/Permissions";

export const ProductFruitsConfig = () => {
  const { user, organization, group } = usePermissions();
  const userInfo = useMemo(
    () => ({
      username: user?.id,
      email: user?.userInfo?.email,
      firstname: user?.userInfo?.firstName,
      lastname: user?.userInfo?.lastName,
      props: { organization: organization?.title, group: group?.title },
    }),
    [user, organization, group]
  );
  return process.env.REACT_APP_PRODUCT_FRUITS_PROJECT && user?.id ? (
    <ProductFruits
      projectCode={process.env.REACT_APP_PRODUCT_FRUITS_PROJECT}
      language="en"
      {...userInfo}
    />
  ) : null;
};
