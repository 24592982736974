import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useAsync, useLocalStorage } from "react-use";

import { Modal, Button } from "react-bootstrap";
import { Auth } from "@aws-amplify/auth";
import { gql, useLazyQuery } from "@apollo/client";
import {
  getUserGroupMembership,
  getUserOrganizationMembership,
  getGroupsInOrganization,
  getMyUserMeta,
} from "graphql/queries";
import {
  GetGroupsInOrganizationQuery,
  GetGroupsInOrganizationQueryVariables,
  GetUserGroupMembershipQuery,
  GetUserGroupMembershipQueryVariables,
  GetUserOrganizationMembershipQuery,
  GetUserOrganizationMembershipQueryVariables,
  GetUserQuery,
  GetMyUserMetaQuery,
  GetMyUserMetaQueryVariables,
} from "API";
import {
  useGetCurrentUser,
  useGetUsersInGroup,
  useGetUsersInOrganization,
  UserType,
} from "utils/connectors/userConnectors";
import { useAuthState } from "utils/AuthState";
import PasswordReset from "Modules/Customers/UserSettings/PasswordReset";
import * as Sentry from "@sentry/react";

const scope = new Sentry.Scope();

export const PermissionsContext = createContext<any>({
  organization: { id: "" },
  organizationRole: "",
  groupRole: "",
  userRole: "",
  group: { id: "" },
  hasGroup: false,
  userId: null,
  user: null,
  setGroupId: async (id: string) => null,
  getOrganizationGroup: (id?: string | null): null | { title: string } => null,
  refetchGroups: () => null,
  refetchOrganizations: () => null,
  getGroupUser: (id: string): UserType | null => null,
});

export const Permissions = ({ children }) => {
  const { isAuthenticated, userAttributes } = useAuthState();

  const [getUserMeta, { data: _userMeta, refetch: refetchUserMeta }] =
    useLazyQuery<GetMyUserMetaQuery, GetMyUserMetaQueryVariables>(
      gql(getMyUserMeta)
    );

  const userMeta = _userMeta?.getMyUserMeta;

  const [
    getUserOrganizations,
    { data: userOrganizations, refetch: refetchOrganization },
  ] = useLazyQuery<
    GetUserOrganizationMembershipQuery,
    GetUserOrganizationMembershipQueryVariables
  >(gql(getUserOrganizationMembership));

  const organizations =
    userOrganizations?.getUserOrganizationMembership?.items.map((props) => ({
      ...props,
      id: props.organizationID,
      title: props.organization?.name,
    })) || [];

  const [organizationId, setOrganizationId] = useLocalStorage(
    "activeOrg",
    organizations[0]?.id || undefined
  );

  const organization = organizations?.find(
    (org) => org.id === organizationId
  ) || {
    id: null,
    title: "",
    userRole: "",
  };

  const organizationRole = organization.userRole ?? null;

  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    Auth.currentUserInfo().then((currentUserInfo) => {
      const attributes = currentUserInfo?.attributes?.sub;
      setUserId(attributes || null);
    });
  }, [isAuthenticated, userAttributes?.sub]);

  const { users: organizationUsers, refetch: refetchOrganizationusers } =
    useGetUsersInOrganization(organization?.id);

  const _user = useGetCurrentUser(userId);
  const _orgUser = organizationUsers?.find((u: any) => u.id === userId);
  const user: Partial<GetUserQuery["getUser"]> = useMemo(() => {
    return Object.assign(_orgUser ?? {}, _user.user ?? {});
  }, [_orgUser, _user]);

  const [getUserGroups, { data: _userGroups, refetch: refetchUserGroups }] =
    useLazyQuery<
      GetUserGroupMembershipQuery,
      GetUserGroupMembershipQueryVariables
    >(gql(getUserGroupMembership));

  const [
    getOrganizationGroups,
    { data: _organizationGroups, refetch: refetchOrganizationGroups },
  ] = useLazyQuery<
    GetGroupsInOrganizationQuery,
    GetGroupsInOrganizationQueryVariables
  >(gql(getGroupsInOrganization));

  useEffect(() => {
    if (userId) {
      getUserMeta({
        variables: {
          id: userId,
        },
      });
      getUserOrganizations({
        variables: {
          id: userId,
        },
      });
      getUserGroups({
        variables: {
          id: userId,
        },
      });
    }
  }, [userId, getUserMeta, getUserOrganizations, getUserGroups]);
  useEffect(() => {
    if (organizationId) {
      getOrganizationGroups({
        variables: {
          id: organizationId,
        },
      });
    }
  }, [organizationId, getOrganizationGroups]);

  useEffect(() => {
    if (!organizationId && organizations?.[0]?.id) {
      setOrganizationId(organizations[0].id);
    }
  }, [organizations]);

  const _groups =
    (user as any).userRole !== undefined
      ? (user as any)?.userRole === "admin"
        ? _organizationGroups?.getGroupsInOrganization?.items.map((group) => {
            return {
              ...group,
              userRole: "admin",
            };
          })
        : _userGroups?.getUserGroupMembership?.items
      : [];

  const groups =
    (_groups as any)?.map(({ id, groupID, itemID, group, ...other }) => ({
      id: groupID ?? itemID,
      title: group?.title,
      groupID: groupID ?? itemID,
      organizationID: group?.organizationID,
      groupName: group?.title,
      shortTitle: group?.shortTitle,
      riskThreshold: group?.riskThreshold,
      groupEmailFooter: group?.groupEmailFooter,
      ...other,
    })) || [];

  const organizationGroups = groups.filter(
    ({ organizationID }) => organizationID === organizationId
  );

  const getOrganizationGroup = (groupID?: string | null) => {
    return organizationGroups.find(({ id }) => id === groupID);
  };

  const [groupsStore, setGroupsStore] = useLocalStorage("activeGroups", {});

  const groupId = (organizationId && groupsStore?.[organizationId ?? ""]) ?? "";

  const changeActiveGroup = (_orgId, _groupId) => {
    setGroupsStore((groups) => ({ ...groupsStore, [_orgId]: _groupId }));
  };

  const setGroupId = async (g) => {
    return new Promise(async (resolve, reject) => {
      if (groups.length > 0 && !groups.map(({ id }) => id).includes(g)) {
        reject("Not a member of group");
      }

      changeActiveGroup(organizationId, g);
      resolve(true);
    });
  };

  const group = organizationGroups.find((gr) => gr.groupID === groupId) ||
    organizationGroups[0] || { id: null, title: null };

  const groupRole = group?.userRole || null;

  const { users: groupUsers, refetch: refetchGroupUsers } = useGetUsersInGroup(
    group?.id
  );

  const getUser = (userList) => (id) => userList?.find((u) => u.id === id);
  const getOrganizationUser = useCallback(getUser(organizationUsers), [
    organizationUsers,
  ]);
  const getGroupUser = useCallback(getUser(groupUsers), [groupUsers]);

  const refetchUsers = () => {
    _user.refetch();
    refetchOrganizationusers();
    refetchGroupUsers();
  };
  const refetchGroups = () => {
    refetchOrganizationGroups?.();
    refetchUserGroups?.();
  };
  const refetchOrganizations = () => {
    refetchOrganization?.();
  };

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setTag("organizationID", organization.id ? organization.id : "NA");
      scope.setTag(
        "organizationName",
        organization.title ? organization.title : "NA"
      );
      scope.setTag("groupID", group.id ? group.id : "NA");
      scope.setTag("groupName", group.title ? group.title : "NA");
      scope.setTag("userID", user.id ? user.id : "NA");
      scope.setTag("userName", (user as any)?.displayName ?? "NA");
      scope.setTag("email", user.email ? user.email : "NA");
    });
  }, [user, organization, group]);

  return (
    <PermissionsContext.Provider
      value={
        {
          organizations,
          organization,
          organizationRole,
          organizationUsers,
          getOrganizationUser,
          refetchOrganizationusers,
          hasOrganization: !!organization?.id,
          setOrganizationId,
          getOrganizationGroup,
          groups: organizationGroups,
          group,
          groupRole,
          groupUsers,
          getGroupUser,
          refetchGroupUsers,
          refetchGroups,
          refetchOrganizations,
          hasGroup: !!(organization?.id && group?.id),
          setGroupId,
          userId,
          user,
          refetchUsers,
          userMeta,
          refetchUserMeta,
        } as any
      }
    >
      {/* <Button
        onClick={() => {
          // @ts-ignore
          methodDoesNotExist();
        }}
      >
        ERRRORTTTRR!
      </Button> */}
      {children}
      {/* {organization?.id && userMeta?.forceResetPassword === true && (
        <PasswordReset show={true} onHide={() => {}} required={true} />
      )} */}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionsContext);
