import { gql, useLazyQuery } from "@apollo/client";
import {
  GetIssueChangesInGroupQuery,
  GetIssueChangesInGroupQueryVariables,
  GetIssuev3Query,
  GetIssuev3QueryVariables,
  GetIssuesInGroupv3Query,
  GetIssuesInGroupv3QueryVariables,
  GetOwnedIssuesQuery,
  GetOwnedIssuesQueryVariables,
} from "API";
import { usePermissions } from "common/Permissions";
import {
  getIssuev3,
  getIssueChangesInGroup,
  getIssuesInGroupv3,
  getOwnedIssues,
} from "graphql/queries";
import { DateTime } from "luxon";
import { getCompositeId, getItemApprover } from "utils/dataFormatters";
import { formatSeverity, formatStatus } from "utils/formatStatus";
import { useGetGroupByRelatedItem } from "utils/useGetGroupByRelatedItems";
import { useGroupCheck } from "utils/useGroupCheck";
import {
  useProtectedPolling,
  useProtectedQuery,
} from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";

const issuesInGroupTransform = (response) => {
  return (
    response.data?.getIssuesInGroupv3?.items.map((item) => item.issue) || []
  )
    .filter((item) => item?.isTemplate !== true)
    .map(sortToID("ISSUE#"));
};

export const useGetOwnedIssues = () => {
  const { hasGroup, userId } = usePermissions();

  const response = useProtectedQuery<
    GetOwnedIssuesQuery,
    GetOwnedIssuesQueryVariables
  >(
    gql(getOwnedIssues),
    {
      variables: {
        id: userId,
      },
    },
    hasGroup
  );

  const issues = (response.data?.getOwnedIssues?.items || [])
    .map(({ issue }) => issue)
    .map(sortToID("ISSUE#"));

  return { ...response, issues };
};

export const useGetIssuesInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetIssuesInGroupv3Query,
    GetIssuesInGroupv3QueryVariables
  >(
    gql(getIssuesInGroupv3),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const issues = issuesInGroupTransform(response);

  const issuesTemplates = (
    response.data?.getIssuesInGroupv3?.items.map((item) => item.issue) || []
  )
    .filter((item) => item?.isTemplate === true)
    .map(sortToID("ISSUE#"));

  return { ...response, issues, issuesTemplates };
};

export const useGetIssuesInSelectedGroup = () => {
  const [_getIssuesInSelectedGroup, response] = useLazyQuery<
    GetIssuesInGroupv3Query,
    GetIssuesInGroupv3QueryVariables
  >(gql(getIssuesInGroupv3));

  return (id) => _getIssuesInSelectedGroup(id).then(issuesInGroupTransform);
};

export const useGetIssue = (id) => {
  const { group, hasGroup } = usePermissions();
  const response = useProtectedQuery<GetIssuev3Query, GetIssuev3QueryVariables>(
    gql(getIssuev3),
    {
      variables: {
        groupID: group.id,
        id,
      },
    },
    hasGroup
  );

  const issue = sortToID("ISSUE#")(response.data?.getIssuev3);

  useProtectedPolling(issue, response);

  const canAccess = useGroupCheck(issue?.groupID, "/issues");

  return { ...response, issue: canAccess && issue };
};

export const useGetIssueChangesInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetIssueChangesInGroupQuery,
    GetIssueChangesInGroupQueryVariables
  >(
    gql(getIssueChangesInGroup),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const changes = (response.data?.getIssueChangesInGroup?.items || [])
    .map(sortToID("CHANGELOG#ISSUES#"))
    .sort(
      (a, b) =>
        DateTime.fromISO(b.createdAt).toMillis() -
        DateTime.fromISO(a.createdAt).toMillis()
    );

  return { ...response, changes };
};

export const useGetIssueTemplatesInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetIssuesInGroupv3Query,
    GetIssueChangesInGroupQueryVariables
  >(
    gql(getIssuesInGroupv3),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const issueTemplates: Array<GetIssuev3Query["getIssuev3"]> = (
    response.data?.getIssuesInGroupv3?.items.map((item) => item.issue) || []
  )

    .filter((item) => item?.isTemplate)
    .map(sortToID("ISSUE#"))
    .map(({ ...issue }) => ({ ...issue, relationType: "issue" }));
  return { ...response, issueTemplates };
};

export const useGetDownloadIssueObj = (item, files) => {
  const { getOrganizationUser, groups } = usePermissions();
  const groupedRelatedItems = useGetGroupByRelatedItem(
    item?.issueRelatedItems?.items,
    groups
  );
  if (item) {
    const issueArray = [
      "title",
      "status",
      "createdAt",
      "lastModified",
      "reportedBy",
      "reportedOn",
      "source",
      "description",
      "resolutionCriteria",
      "assumptions",
      "notes",
      "owner",
      "priority",
      "severity",
      "requiresActionPlan",
      "requiresScenario",
      "nextReview",
      "relatedItems",
      "approver",
    ];
    const selectArray = (arr, obj) =>
      arr.reduce(
        (r, e) => Object.assign(r, obj[e] ? { [e]: obj[e] } : null),
        {}
      );
    item = {
      ...item,
      title: `${item.title} (${getCompositeId(item)})`,
      status: formatStatus(item?.status),
      approver: getItemApprover(item, "issueApprovers"),
      createdAt: DateTime.fromISO(item?.createdAt)
        .toLocaleString(DateTime.DATETIME_MED)
        .replace(/,/g, ""),
      lastModified: DateTime.fromISO(item?.lastModified)
        .toLocaleString(DateTime.DATETIME_MED)
        .replace(/,/g, ""),
      owner: getOrganizationUser(item?.owner)?.displayName,
      resolutionCriteria: item.acceptanceCriteria,
      requiresActionPlan: item.requiresActionplan,
      severity: formatSeverity(item.severity),
      relatedItems: groupedRelatedItems || " ",
    };
    return selectArray(issueArray, item);
  }
  return null;
};
