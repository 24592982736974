import React, { useState } from "react";
import {
  VictoryBoxPlot,
  VictoryChart,
  VictoryLabel,
  VictoryAxis,
  VictoryTheme,
  VictoryContainer,
  LineSegment,
} from "victory";
import {
  Button, Col, ColProps, Modal, 
} from "react-bootstrap";
import useDimensions from "react-use-dimensions";
import { humanReadable } from "./formatting";

import "./BoxPlot.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

interface BoxPlotAnalysisInput {
  title: string;
  data: number[];
}

export type IBoxPlotInputs = {
  data: BoxPlotAnalysisInput[];
  label?: string;
  height?: number;
  width?: number;
  displayRawValues?: boolean;
  yFormat?: (y: any) => any;
  style?: object;
} & ColProps;

export const BoxPlotSVG: React.FC<IBoxPlotInputs> = ({
  data,
  height = 350,
  width = 500,
  yFormat = (y) => y,
  displayRawValues = false,
}) => {
  const _data = data.filter(({ data }) => !!data);
  const [internalHeight, internalWidth] = [height * 1, width * 1];

  const formattedData = _data.map((analysis) => ({
    x: analysis.title,
    y: analysis.data,
  }));
  return(
<svg
        height={height}
        preserveAspectRatio="none"
        width="100%"
        viewBox={`0 0  ${internalWidth} ${internalHeight}`}
        pointerEvents=""
        className="box-plot-graph"
>
        <VictoryChart
          containerComponent={<VictoryContainer style={{ margin: "auto" }} />}
          domainPadding={60}
          padding={{
            left: 50,
            bottom: 50,
            top: 20,
            right: 50,
          }}
          width={internalWidth}
          height={internalHeight}
          standalone={false}
          theme={VictoryTheme.material}
        >
          <VictoryAxis
            style={{
              tickLabels: {
                fontSize: 10,
              },
              grid: {
                strokeWidth: "0.5px",
                stroke: "gray",
              },
            }}
            tickFormat={(val) =>
              (!displayRawValues && yFormat(humanReadable(val))) || yFormat(val)
            }
            dependentAxis
          />
          <VictoryAxis
            gridComponent={<></>}
            style={{
              tickLabels: {
                fontSize: 10,
              },
            }}
            tickLabelComponent={
              <VictoryLabel
                dy={(index) => {
                  return index.datum % 2 === 0 ? 10 : 0;
                }}
                textAnchor="middle"
              />
            }
            axisComponent={<LineSegment className="domain-axis" />}
          />
          <VictoryBoxPlot boxWidth={40} data={formattedData} />
        </VictoryChart>
</svg>
  )
}

export const BoxPlot: React.FC<IBoxPlotInputs> = ({
  data,
  label,
  height = 350,
  displayRawValues = false,
  yFormat = (y) => y,
  ...props
}) => {
  const [showGuidance, setShowGuidance] = useState(false);
  const [measureRef, { width }] = useDimensions();

  const graphProps = {
    data,
    height,
    width,
    displayRawValues,
    yFormat,
  }

  return (
    <Col {...props} ref={measureRef}>
      <div
        style={{
          width: "100%",
          fontWeight: "bolder",
          fontSize: "1.2em",
          textAlign: "center",
          paddingTop: "10px",
        }}
      >
        {label}
        <Button
          variant="link"
          onClick={() => {
            setShowGuidance(true);
          }}
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </Button>
        <Modal
          show={showGuidance}
          onHide={() => {
            setShowGuidance(false);
          }}
        >
          <Modal.Header>About Box Plots</Modal.Header>
          <Modal.Body
            style={{ padding: "0px", margin: "auto", overflow: "hidden" }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/b2C9I8HuCe4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => {
                setShowGuidance(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <BoxPlotSVG {...graphProps}/>
    </Col>
  );
};
