import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createContext, useCallback, useContext, useState, 
} from "react";
import { Badge, Button } from "react-bootstrap";
import { CreateComparison } from "./CreateComparison";
import { CreateBulkEdit } from "common/createBulkEdit";
import "./TableComparison.scss";
import { CreateBulkArchive } from "common/createBulkArchive";

const ScenarioComparisonContext = createContext<{
  checked: Array<string>;
  total: number;
  setCheckedStatus: (id: string) => void;
  getIsChecked: (id: string) => boolean;
  canAdd: boolean;
  clear: Function;
}>({
      checked: [],
      total: 0,
      setCheckedStatus: (id) => {},
      getIsChecked: (id) => false,
      canAdd: true,
      clear: () => {},
    });

export const useScenarioComparisonContext = () =>
  useContext(ScenarioComparisonContext);

const useScenarioComparison = () => {
  const [checked, setChecked] = useState<Array<string>>([]);

  const getIsChecked = useCallback(
    (id: string) => {
      return checked.includes(id);
    },
    [checked],
  );

  const setCheckedStatus = (id) => {
    if (getIsChecked(id)) {
      setChecked((checked) => checked.filter((c) => c !== id));
    } else {
      setChecked((checked) => [...checked, id]);
    }
  };

  const clear = () => {
    setChecked([]);
  };

  const canAdd = checked.length < 3;

  return {
    checked,
    total: checked.length,
    setCheckedStatus,
    getIsChecked,
    canAdd,
    clear,
  };
};
let allData;
export const ScenarioComparison = ({ children, scenarios, modifyItem }) => {
  allData = scenarios;
  const scenarioComparison = useScenarioComparison();
  return (
    <ScenarioComparisonContext.Provider value={scenarioComparison}>
      {children}
      {scenarioComparison.total > 0 && (
        <div className="tableComparison">
          <div className="buffer" />
          <div style={{ position: "relative" }}>
            <div className="clearfix dock">
              <div className="float-right">
              <CreateBulkArchive items={scenarios} type={scenarios?.[0]?.__typename} modifyItem={modifyItem}/>
                <CreateComparison scenarios={scenarios} /> 
                <CreateBulkEdit
                  items={scenarios}
                  type={scenarios?.[0]?.__typename}
                />
              </div>

              <h5 className="mt-2">
                <Button
                  variant="link"
                  aria-label="Notification"
                  onClick={() => {
                    scenarioComparison.clear();
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faTimes} />
                </Button>
{" "}
                <Badge pill style={{ marginLeft: "15px" }}>
                  {scenarioComparison.total}
                </Badge>
{" "}
                Scenarios Selected
              </h5>
            </div>
          </div>
        </div>
      )}
    </ScenarioComparisonContext.Provider>
  );
};

export const ScenarioSelectorCheckbox: React.FC<{ scenario, userRole }> = ({
  scenario, userRole,
}) => {
  const { setCheckedStatus, getIsChecked } = useScenarioComparisonContext();
  const isChecked = getIsChecked(scenario.id);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  return (
    userRole !== 'read'
      ? <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input"
        aria-labelledby="checkbox"
        type="checkbox"
        id={scenario?.id || "selectAll"}
        checked={scenario === "selectAll" ? isCheckedAll : isChecked}
        onChange={() => {
          if (scenario === "selectAll") {
            allData?.map((x) => setCheckedStatus(x?.id));
            setIsCheckedAll(!isCheckedAll);
          } else {
            setCheckedStatus(scenario.id);
          }
        }}
      />
{" "}
      <label
        className="custom-control-label"
        htmlFor={scenario?.id || "selectAll"}
      />
        </div>
      : <></>
  );
};
