import { DateTime } from "luxon";
import React from "react";
import { Card } from "react-bootstrap";
import { dateFromCustomFormat } from "utils/dataFormatters";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { useHistory } from "react-router";

const localizer = momentLocalizer(moment);

export const PastReview = ({ items }) => {
  const data = items.filter(
    (item) =>
      item?.nextReview
      && (dateFromCustomFormat(item?.nextReview, "MM/dd/yyyy") as any)
        < DateTime.local().plus({ days: 30 }),
  );
  data
    && data.forEach(function (element) {
      return (element.allDay = true);
    });

  const history = useHistory();
  const handleSelected = (event) => {
    if (event.__typename === "Issue") {
      history.push(`/issues/${event.id}`);
    } else if (event.__typename === "Scenario") {
      history.push(`/risk/${event.id}`);
    } else if (event.__typename === "ActionPlan") {
      history.push(`/actionplans/${event.id}`);
    } else if (event.__typename === "Task") {
      history.push(`actionplans/${event.parentID}/tasks/${event.id}`);
    }
  };
  const handleBackgorund = (event) => {
    if (event.__typename === "Issue") {
      return {
        style: {
          backgroundColor: "#d2787b",
          borderLeft: "4px solid #c16568",
          color: "#FFFFFF",
        },
      };
    }
    if (event.__typename === "Scenario") {
      return {
        style: {
          backgroundColor: "#9994ca",
          borderLeft: "4px solid #8d88c0",
          color: "#FFFFFF",
        },
      };
    }
    if (event.__typename === "ActionPlan") {
      return {
        style: {
          backgroundColor: "#189889",
          borderLeft: "4px solid #138a7c",
          color: "#FFFFFF",
        },
      };
    }
    if (event.__typename === "Task") {
      return {
        style: {
          backgroundColor: "#8a6a78",
          borderLeft: "4px solid #7e606d",
          color: "#FFFFFF",
        },
      };
    }
  };
  return (
    <Card.Body>
      <Calendar
        localizer={localizer}
        events={data || []}
        startAccessor="nextReview"
        endAccessor="nextReview"
        style={{ height: 500 }}
        tooltipAccessor={(e) =>
          `${e.title} Status:${e.status} Type:${e.__typename}`
        }
        onSelectEvent={handleSelected}
        eventPropGetter={handleBackgorund}
      />
    </Card.Body>
  );
};
