import { usePermissions } from "common/Permissions";
import React from "react";
import { Col, Card } from "react-bootstrap";
import OrganizationPermissionsViewGroups from "./OrganizationPermissionsView.Groups";
import OrganizationPermissionsViewUsers from "./OrganizationPermissionsView.Users";

const OrganizationPermissionsView = () => {
  const { organization } = usePermissions();

  return (
    (organization?.userRole === "admin" && (
      <Col sm={12}>
        <Card>
          <Card.Header>
            Organization:
            {organization?.title}
          </Card.Header>
          <Card.Body>
            <OrganizationPermissionsViewUsers />
            <OrganizationPermissionsViewGroups />
          </Card.Body>
        </Card>
      </Col>
    ))
    || null
  );
};

export default OrganizationPermissionsView;
