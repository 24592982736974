import { GetSourceQuery } from "API";
import { ITableRecordFormatting } from "common/TabbedTable";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useGetSourcesInGroup } from "Modules/Customers/Issues/IssuePage/Sources";
import { ModifySourceButton } from "./ModifySourceButton";
import { HGRichTextRead } from "common/HGForm/HGRichText";

type schemaParams = {
  archiveSource?: Function;
  refetch?: Function;
  showGroupPrefix?: boolean;
  userRole?: string;
};

type SourceType = NonNullable<GetSourceQuery["getSource"]>;

export const useSourceSchema = (params?: schemaParams) => {
  const { sources } = useGetSourcesInGroup();

  const sourceSchema: Array<ITableRecordFormatting<SourceType>> =    useMemo(() => {
    return [
      {
        id: "title",
        name: "Title",
        format: (source) => source.title,
        required: true,
        first: true,
      },
      {
        id: "year",
        name: "Year",
        format: (source) => source.year,
        required: true,
        first: true,
      },
      {
        id: "description",
        name: "Description",
        format: (source) => source.description,
        tableDisplayFormat: (source) => (
            <HGRichTextRead content={source.description} />
        ),
        required: true,
        first: true,
      },
      {
        id: "",
        name: "",
        format: () => "",
        tableDisplayFormat: (source) => (
            <>
              <ModifySourceButton
                source={source}
                onSave={() => {
                  params?.refetch?.();
                }}
              />
              {
                params?.userRole !== 'read'
                  ? <Button
                variant="outline-primary"
                onClick={() =>
                  params?.archiveSource?.(source.id, !source.archived)
                }
                  >
                {(source.archived && "Unarchive") || "Archive"}
                    </Button>
                  : <></>
              }
              
            </>
        ),
        required: true,
        last: true,
      },
    ];
  }, [sources]);

  return sourceSchema;
};
