import { useMutation, gql } from "@apollo/client";
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  CreateUserInput,
  UserRoles,
} from "API";
import { HGForm, HGFormControl, HGFormSubmit } from "common/HGForm";
import { usePermissions } from "common/Permissions";
import { createUser } from "graphql/mutations";
import React, { useState, useMemo } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { cancelPopupMessage } from "utils/globalVariables";
import { DateTime } from "luxon";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import * as yup from "yup";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { organizationUserAction } from "./OrganizationsAction";
import { HGSelect, HGInputBox } from "common/HGForm/Inputs";

const CreateUser: React.FC = () => {
  const { organization, organizationUsers, refetchUsers, groups, group } =
    usePermissions();
  const [show, setShow] = useState(false);

  const organizationUsersEmail = organizationUsers.map((user) => {
    return user.user.email;
  });

  const createUserSchema = useMemo(() => {
    return yup.object<Partial<CreateUserInput>>({
      email: yup
        .string()
        .email("Please enter a valid email address")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .default("")
        .required()
        .label("Email")
        .test(
          "check-duplication",
          "A user with this email address already exists.",
          (value) => {
            const index = organizationUsersEmail.findIndex((element) => {
              return element.toLowerCase() === value?.toLowerCase().trim();
            });
            return index === -1;
          }
        ),
    });
  }, [organizationUsersEmail]);

  const [runAttachUser] = useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(gql(createUser));
  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShow(false);
    }
  }
  return (
    <>
      <Button
        variant="outline-primary"
        onClick={() => {
          setShow(true);
        }}
      >
        Create User
      </Button>
      <Modal
        show={show}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async ({
            email,
            groupID,
            groupRole,
            firstName,
            lastName,
          }) => {
            const username = email.replace(/[@.+]/g, "_").toLowerCase();
            return runAttachUser({
              variables: {
                input: [
                  {
                    email: email.trim().toLowerCase(),
                    username,
                    organizationID: organization.id,
                    userRole: UserRoles.read,
                    groupID,
                    groupRole,
                    firstName,
                    lastName,
                  },
                ],
              },
            }).then(async (response) => {
              await refetchUsers();
              return setShow(false);
            });
          }}
          resolver={createUserSchema}
        >
          <Modal.Header>Create User</Modal.Header>
          <Modal.Body>
            <HGInputBox name="email" label="Email" />
            <HGSelect
              name="groupID"
              label="Department"
              options={groups}
              defaultValue={group.id}
            />
            <HGSelect
              name="groupRole"
              label="Department Role"
              options={[
                { id: UserRoles.write, title: "Member" },
                { id: UserRoles.admin, title: "Admin" },
                { id: UserRoles.read, title: "Informed (Read-only)" },
              ]}
            />
            <HGInputBox name="firstName" label="First Name" />
            <HGInputBox name="lastName" label="Last Name" />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn cancel-btn"
              style={{ marginRight: "5px" }}
              data-dismiss="modal"
              onClick={() => {
                Confirmation();
              }}
            >
              Cancel
            </button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};

const OrganizationPermissionsViewUsers: React.FC = () => {
  const { organizationUsers, user } = usePermissions();

  const dateComparator = (date1, date2) => {
    const date1Number = monthToComparableNumber(date1);
    const date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  };
  const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };
  const [columnDefs, _setColumnDefs] = useState([
    {
      field: "User",
      unSortIcon: true,
      comparator: customComparator,
      width: 300,
    },
    {
      field: "Role",
      unSortIcon: true,
      width: 250,
    },
    {
      field: "Last Seen",
      comparator: dateComparator,
      unSortIcon: true,
      width: 250,
    },
    {
      field: "Action",
      minWidth: 250,
      cellRenderer: organizationUserAction,
      sortable: false,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      sortable: true,
      filter: true,
    };
  }, []);
  const orgMemberData = [] as any;

  organizationUsers.map((item) => {
    orgMemberData.push({
      User: item?.displayName,
      Role: item?.userInfo?.inactive
        ? "Inactive"
        : item.userRole === "write" || item.userRole === "read"
        ? "User"
        : "Administrator",
      Action:
        user.id === item.id
          ? ""
          : item?.userInfo?.inactive
          ? "inactive"
          : item.userRole,
      "Last Seen":
        item.lastSeen || item?.user?.lastSeen
          ? DateTime.fromISO(item?.user?.lastSeen).toFormat("MM/dd/yyyy HH:mm ")
          : "",
      ID: item?.id,
    });
  });

  // eg 29/08/2004 gets converted to 20040829
  const monthToComparableNumber = (date) => {
    if (date === undefined || date === null || date.length <= 10) {
      return null;
    }
    const yearNumber = Number.parseInt(date.substring(6, 10));
    const dayNumber = Number.parseInt(date.substring(3, 5));
    const monthNumber = Number.parseInt(date.substring(0, 2));
    return yearNumber * 10000 + monthNumber * 100 + dayNumber;
  };
  return (
    <Card>
      <Card.Header>
        <span className="float-right">
          <CreateUser />
        </span>
        Users
      </Card.Header>
      <Card.Body>
        <div className="ag-theme-alpine" style={{ height: 250, width: "100%" }}>
          <AgGridReact
            overlayNoRowsTemplate="No data found"
            modules={AllCommunityModules}
            rowData={orgMemberData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default OrganizationPermissionsViewUsers;
