import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createContext, useCallback, useContext, useState, 
} from "react";
import { Badge, Button } from "react-bootstrap";
import { CreateBulkEditThreats } from "./CreateBulkEditThreats";
import { CreateBulkEditControls } from "./CreateBulkEditControls";
import { CreateBulkEdit } from "common/createBulkEdit";
import "../../Risk/Comparison/TableComparison/TableComparison.scss";
import { CreateBulkArchive } from "common/createBulkArchive";

const AssetComparisonContext = createContext<{
  checked: Array<string>;
  total: number;
  setCheckedStatus: (id: string) => void;
  getIsChecked: (id: string) => boolean;
  // canAdd: boolean;
  clear: Function;
}>({
      checked: [],
      total: 0,
      setCheckedStatus: (id) => {},
      getIsChecked: (id) => false,
      // canAdd: true,
      clear: () => {},
    });

export const useAssetComparisonContext = () =>
  useContext(AssetComparisonContext);

const useAssetComparison = () => {
  const [checked, setChecked] = useState<Array<string>>([]);

  const getIsChecked = useCallback(
    (id: string) => {
      return checked.includes(id);
    },
    [checked],
  );

  const setCheckedStatus = (id) => {
    if (getIsChecked(id)) {
      setChecked((checked) => checked.filter((c) => c !== id));
    } else {
      setChecked((checked) => [...checked, id]);
    }
  };

  const clear = () => {
    setChecked([]);
  };

  // const canAdd = checked.length < 3; 

  return {
    checked,
    total: checked.length,
    setCheckedStatus,
    getIsChecked,
    // canAdd,
    clear,
  };
};
let allData;
export const AssetComparison = ({ children, assets, modifyItem }) => {
  allData = assets;
  const assetComparison = useAssetComparison();
  return (
    <AssetComparisonContext.Provider value={assetComparison}>
      {children}
      {assetComparison.total > 0 && (
        <div className="tableComparison">
          <div className="buffer" />
          <div style={{ position: "relative" }}>
            <div className="clearfix dock">
              <div className="float-right">
              <CreateBulkArchive items={assets} type={assets?.[0]?.__typename} modifyItem={modifyItem}/>
                <CreateBulkEditControls assets={assets} /> 
                <CreateBulkEditThreats assets={assets} /> 
                <CreateBulkEdit
                  items={assets}
                  type={assets?.[0]?.__typename}
                />
              </div>

              <h5 className="mt-2">
                <Button
                  variant="link"
                  aria-label="Notification"
                  onClick={() => {
                    assetComparison.clear();
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faTimes} />
                </Button>
{" "}
                <Badge pill style={{ marginLeft: "15px" }}>
                  {assetComparison.total}
                </Badge>
{" "}
                Assets Selected
              </h5>
            </div>
          </div>
        </div>
      )}
    </AssetComparisonContext.Provider>
  );
};

export const AssetSelectorCheckbox: React.FC<{ asset, userRole }> = ({
  asset, userRole,
}) => {
  const { setCheckedStatus, getIsChecked } = useAssetComparisonContext();
  const isChecked = getIsChecked(asset.id);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  return (
    userRole !== 'read'
      ? <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input"
        aria-labelledby="checkbox"
        type="checkbox"
        id={asset?.id || "selectAll"}
        checked={asset === "selectAll" ? isCheckedAll : isChecked}
        onChange={() => {
          if (asset === "selectAll") {
            allData?.map((x) => setCheckedStatus(x?.id));
            setIsCheckedAll(!isCheckedAll);
          } else {
            setCheckedStatus(asset.id);
          }
        }}
      />
{" "}
      <label
        className="custom-control-label"
        htmlFor={asset?.id || "selectAll"}
      />
        </div> : <></>
  );
};
