import { useApolloClient, useMutation } from "@apollo/client";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { sleep } from "utils/useSleep";

export const useMutationWithManualUpdate = <T, U>(
  query,
  { mutationKey, currentData, ...options },
  { message },
) => {
  const client = useApolloClient();
  return useMutation<T, U>(query, {
    ...options,
    errorPolicy: "none",
    onError: (err) => {
      err.graphQLErrors.map((err2: any) =>
        ToastifyQueue(err2.errorInfo.msg,'danger'),
      );
    },
    onCompleted: (response) => {
      const { id } = response[mutationKey];

      const data = {
        [mutationKey]: {
          ...currentData,
          ...Object.fromEntries(
            Object.entries(response[mutationKey]).filter(([_, v]) => v != null),
          ),
          id,
          sort: id,
        },
      };

      client.writeQuery({
        query,
        data,
        variables: { id },
      });

      return sleep(1000)
        .then(() => {
          ToastifyQueue(message,'success');
        })
        .then(() => {
          return options?.onCompleted?.(response);
        });
    },
  });
};
