import React, { useMemo } from "react";
import { useTabs } from "./Tabs";
import { useSelectiveSearch } from "./SelectiveSearchForm";
import { TableGenerator } from "common/FootableTable";
import { useFilterSortPanel } from "common/FilterSortPanel";
import { Row, Col, InputGroup, Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faCheckCircle,
  faFilter,
  faClipboardList,
  faCommentDots,
  IconDefinition,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { CSVExport } from "./CSVExport";
import { Status } from "API";

const emptyTabDetails = {
  Active: {
    icon: faClipboardList,
    header: "You have no active items!",
    text: "Either no items have been created or all items have been closed or archived.",
  },
  Deferred: {
    icon: faClipboardList,
    header: "You have no items with Deferred status",
    text: "Either no items have been created or all items have been closed or archived.",
  },
  "Closed (Pending Approval)": {
    icon: faCommentDots,
    header: "You have no items pending approval!",
    text: "Items that have been flagged for closure approval will appear here. Items should be closed when they have been addressed and/or resolved.",
  },
  Closed: {
    icon: faCheckCircle,
    header: "You have no closed items!",
    text: "Closed items will appear here. Items should be closed when they have been addressed and/or resolved.",
  },
  Archived: {
    icon: faArchive,
    header: "You have no archived items!",
    text: "Archived items will appear here. Items should be archived if they are no longer needed. For example, if someone copies an issue by accident the duplicate can be archived. But don't worry, archived items can always be restored!",
  },
  Complete: {
    icon: faCheck,
    header: "You have no Complete items!",
    text: "Items with status as complete will be listed here",
  },
};

const EmptyTab = ({ type, tabFallback }) => {
  const tab = tabFallback ?? emptyTabDetails[type];

  return (
    <Row style={{ paddingTop: "15px" }}>
      <Col sm={12} style={{ textAlign: "center" }}>
        <div style={{ paddingTop: "20px", paddingBottom: "30px" }}>
          <FontAwesomeIcon
            size="10x"
            icon={tab.icon}
            style={{
              color: "#e6e6e6",
              display: "inline",
            }}
          />
        </div>
        <h3>{tab.header}</h3>
        <p
          style={{
            maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {tab.text}
        </p>
      </Col>
    </Row>
  );
};

export interface ITableRecordFormatting<T> {
  id?: string;
  name: string;
  format?: (input: T) => string | null | undefined | number;
  type?: string;
  displayFormat?: (input: T) => string | null;
  tableDisplayFormat?: (input: T) => React.ReactElement | string | null;
  tableDisplayStyle?: any;
  first?: boolean;
  last?: boolean;
  required?: boolean;
  defaultVisible?: boolean;
  visible?: boolean;
  optionValues?: any[];
}

export const useTabbedTable = <T extends { __typename: string }>(
  localStorageKey: string,
  _data: Array<T>,
  formatting: Array<ITableRecordFormatting<NonNullable<T>>>,
  downloadFn?: ((item, files) => {}) | null,
  options?: {
    hideTabs?: boolean;
    disableTabs?: boolean;
    disableColumnEdit?: boolean;
    tabFallback?: { icon: IconDefinition; header: string; text?: string };
    tabChoices?: string[];
    sortBy?: string;
    parentTab?: string;
    sortOrder?: string;
    rowFormatter?: (input: T) => Object;
  },
  ...props
): any => {
  const data = _data ?? [];
  const tabList = options?.tabChoices ?? [
    "Active",
    "Closed (Pending Approval)",
    "Closed",
    "Archived",
  ];
  const filterFn = (tab: string) => (item: T) => {
    const archived: boolean =
      !!item?.hasOwnProperty("archived") && (item as any).archived;
    const status: Status | null =
      (item?.hasOwnProperty("status") && (item as any).status) ?? null;
    if (options?.parentTab === "Template") {
      return (
        (tab === "Archived" && archived) || (tab === "Active" && !archived)
      );
    }

    return (
      (tab === "Archived" && archived) ||
      (tab === "Active" &&
        !archived &&
        (!status ||
          (status !== Status.Closed &&
            status !== Status.Deferred &&
            status !== Status.Closed_Pending_Approval &&
            status !== Status.Complete))) ||
      (tab === "Deferred" && !archived && status === Status.Deferred) ||
      (tab === "Closed (Pending Approval)" &&
        !archived &&
        status === Status.Closed_Pending_Approval) ||
      (tab === "Closed" && !archived && status === Status.Closed) ||
      (tab === "Complete" && !archived && status === Status.Complete)
    );
  };

  const [currentTab, setTab, TabUI] = useTabs(tabList);
  const currentData = options?.disableTabs
    ? data
    : data?.filter(filterFn(currentTab));
  const activeData = data?.filter(filterFn("Active"));
  const deferredData = data?.filter(filterFn("Deferred"));
  const closedPendingReviewData = data?.filter(
    filterFn("Closed (Pending Approval)")
  );
  const closedData = data?.filter(filterFn("Closed"));
  const archivedData = data?.filter(filterFn("Archived"));
  const completeData = data?.filter(filterFn("Complete"));

  const tabData = {
    Active: options?.disableTabs ? data : activeData,
    Deferred: options?.disableTabs ? data : deferredData,
    "Closed (Pending Approval)": closedPendingReviewData,
    Closed: options?.disableTabs ? data : closedData,
    Complete: options?.disableTabs ? data : completeData,
    Archived: options?.disableTabs ? data : archivedData,
  };

  const { filteredOrder: _filteredOrder, SortUI } = useFilterSortPanel({
    defaultOrder: formatting,
    saveKey: `${localStorageKey}-columns`,
    module: data[0]?.__typename,
  });

  const filteredOrder =
    (options?.disableColumnEdit &&
      formatting.map(({ ...item }) => ({
        ...item,
        required: true,
        visible: true,
      }))) ||
    _filteredOrder;

  const {
    changes,
    setSearchEntry,
    SearchUI,
    filterCallback,
    filteredData,
    filteredFormatting,
  } = useSelectiveSearch<any>({
    itemList: filteredOrder,
    data: currentData,
    localStorageKey: `${localStorageKey}-filter`,
    formatting,
  });

  const allTabFilteredData = filterCallback(data);
  const headerAppend = useMemo(() => {
    return changes.map((field) => {
      return {
        name: field.name,
        value: (
          <Button
            onClick={(e: any) => {
              e.cancelBubble = true;
              e.stopPropagation();
              setSearchEntry(field.name, "");
            }}
            variant="link"
          >
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        ),
      };
    });
  }, [changes, setSearchEntry]);

  const hasData = currentData.length > 0;
  // eslint-disable-next-line

  const ColumnSortFilterUI =
    (hasData && (
      <Container style={{ float: "right", width: "100%", maxWidth: "600px" }}>
        <Row>
          <Col xs={12}>
            <InputGroup className="float-right" size="sm">
              {localStorageKey === "controlsColumnSortOrder" ? (
                ""
              ) : (
                <InputGroup.Prepend>
                  <CSVExport
                    type={data[0]?.__typename || "Issue"}
                    tabData={tabData}
                    tabs={tabList}
                    currentTab={currentTab}
                    tableShape={filteredOrder}
                    filteredData={allTabFilteredData}
                    downloadFn={downloadFn}
                    getFiltered={filterCallback}
                    // filteredData={filteredData}
                    className="float-left"
                  />
                </InputGroup.Prepend>
              )}
              {SearchUI}
              {localStorageKey !== "artifactColumnSortOrder" &&
              localStorageKey !== "controlsColumnSortOrder" &&
              localStorageKey !== "riskComparisonColumnSortOrder" &&
              localStorageKey !== "sourceColumnSortOrder"
                ? SortUI
                : ""}
              {/* {SortUI} */}
            </InputGroup>
          </Col>
        </Row>
      </Container>
    )) ||
    null;

  const TableUI = (
    <>
      {!(options?.hideTabs || options?.disableTabs) && (
        <>
          <TabUI headerAppend={(hasData && SortUI) || null} />
          <br />
        </>
      )}
      {(hasData && (
        <TableGenerator
          {...props}
          options={options}
          localStorageKey={localStorageKey}
          formatting={filteredFormatting}
          data={filteredData}
          headerAppend={headerAppend}
        />
      )) || <EmptyTab type={currentTab} tabFallback={options?.tabFallback} />}
    </>
  );

  return {
    TableUI,
    ColumnSortFilterUI,
    filteredFormatting,
    filteredData,
    activeData,
    setSearchEntry,
    setTab,
  };
};
