import { usePermissions } from "common/Permissions";
import React from "react";
import {
  Row, Col, Breadcrumb, Card, 
} from "react-bootstrap";
import useSetTitle from "utils/useSetTitle";
import { HGRichText } from "common/HGForm/HGRichText";
import { HGForm, HGFormSubmit } from "common/HGForm";
import { modifyOrganization } from "graphql/mutations";
import {
  ModifyOrganizationMutation,
  ModifyOrganizationMutationVariables,
} from "API";
import { useMutation, ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { useGetUsersInOrganization } from "utils/connectors/userConnectors";
import { sleep } from "utils/useSleep";
import ToastifyQueue from "common/Overlays/ToastifyQueue";

export const OrganizationSettings = () => {
  useSetTitle("Organization Settings");
  const permissions = usePermissions();
  const { refetch } = useGetUsersInOrganization(
    permissions.organization.organizationID,
  );
  const [makeOrganizationSettings] = useMutation<
  ModifyOrganizationMutation,
  ModifyOrganizationMutationVariables
  >(gql(modifyOrganization), {
    onCompleted: () => {
      ToastifyQueue('Organization Settings has been changed.','success');
      sleep(500).then(refetch);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message,'danger');
    },
  });
  return (
    <>
      <Row className="dashboard-header">
        <Col xs="12">
          <Breadcrumb>
            <Breadcrumb.Item>Organization Settings</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs="12">
          <h3> Organization Settings</h3>
        </Col>
      </Row>
      <Card>
        <Card.Header>Details</Card.Header>
        <Card.Body>
          <HGForm
            onSubmit={async (input, e) => {
              return makeOrganizationSettings({
                variables: {
                  input: {
                    ...input,
                    id: permissions.organization.organizationID,
                  },
                },
              }).then(() => e.target.reset());
            }}
          >
            <HGRichText
              name="demographics"
              label="Demographics"
              defaultValue={
                permissions?.organization?.organization?.demographics
              }
              required
            />
            <HGRichText
              name="organizationRelationships"
              label="Organization Relationships"
              defaultValue={
                permissions?.organization?.organization
                  ?.organizationRelationships
              }
              required
            />
            <HGFormSubmit />
          </HGForm>
        </Card.Body>
      </Card>
    </>
  );
};
