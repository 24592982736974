import React, { useEffect, useState } from "react";
import FootableTable from "./FootableTable";
import FootableRow from "./FootableTableRow";
import FootableTableHeader from "./FootableTableHeader";
import { useLocalStorage } from "react-use";
import { useLocation } from "react-router-dom";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "./TableGenerator.scss";
import { PageSize } from "utils/globalVariables";

interface TableGeneratorProps {
  formatting: Object[];
  data: Object[];
}

export const TableGenerator = ({ headerAppend, localStorageKey, ...props }) => {
  const [_sortBy, setSortBy] = useLocalStorage(
    `tableSortBy-${localStorageKey}`,
    null
  );
  const [_sortOrder, setSortOrder] = useLocalStorage(
    `tableSortOrder-${localStorageKey}`,
    "asc"
  );

  const location = useLocation();
  const sortBy = props.options?.sortBy || _sortBy;
  const sortOrder = props.options?.sortOrder || _sortOrder;

  const getSortedData = (sortBy, sortOrder) => {
    const dataFormatter =
      sortBy && props.formatting.find((d) => d.name === sortBy);
    if (!dataFormatter) {
      return props.data;
    }

    const data =
      dataFormatter &&
      props.data.sort((a, b) => {
        const format = dataFormatter.sortFormat || dataFormatter.format;

        const _aVal = format(a);
        const _bVal = format(b);

        const aVal =
          _aVal?.isLuxonDateTime || typeof _aVal === "number"
            ? _aVal
            : _aVal?.trim()?.toLowerCase();
        const bVal =
          _bVal?.isLuxonDateTime || typeof _bVal === "number"
            ? _bVal
            : _bVal?.trim()?.toLowerCase();
        if (aVal < bVal) {
          return -1;
        }
        if (aVal > bVal) {
          return 1;
        }
        return 0;
      });

    if (sortOrder === "asc") {
      return data;
    }
    return data.reverse();
  };
  const data = getSortedData(sortBy, sortOrder);
  const getPreference = (a, b) => ({ sortOrder: false, sortBy: false });
  const savePreference = (a, b) => (a) => false;
  const prefSortOrder =
    props.saveSortId && getPreference(props.saveSortId, true);
  const prefSaveSortOrder =
    props.saveSortId && savePreference(props.saveSortId, true);

  const calculatedSortBy =
    (props.saveSortId && prefSortOrder?.sortBy) || sortBy;
  const calculatedSortOrder =
    (props.saveSortId && prefSortOrder?.sortOrder) || sortOrder;
  const dataFilter = JSON.parse(JSON.stringify(data));
  dataFilter.forEach(function (element) {
    if (element) {
      element.tooltip = element?.title;
      return element.module !== undefined
        ? (element.icon = element?.module)
        : "";
    }
    return "";
  });
  dataFilter.map((d, i) =>
    location.pathname.startsWith("/assessments")
      ? ""
      : d?.title?.length > 50
      ? (d.title = `${d?.title.substring(0, 50 - 3)}...`)
      : ""
  );
  const [currentPage, setcurrentPage] = useState(1);

  const indexOfLastItem = currentPage * PageSize;
  const indexOfFirstItem = indexOfLastItem - PageSize;
  const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (e) => {
    setcurrentPage(e);
  };
  useEffect(() => {
    setcurrentPage(1);
  }, [data.length]);

  return (
    <FootableTable>
      <FootableTableHeader
        prefSaveSortOrder={prefSaveSortOrder}
        calculatedSortBy={calculatedSortBy}
        calculatedSortOrder={calculatedSortOrder}
        setSortBy={setSortBy}
        sortBy={sortBy}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        hideSort={props.options?.sortBy}
        formatting={props.formatting.filter((d) => d.id !== "rowFormat")}
        headerAppend={headerAppend}
        type={dataFilter?.[0]?.__typename}
      />
      {props.data.length > 0 ? (
        <>
          {currentData.map((d, i) => {
            const _key = d?.uuid ?? d?.sort ?? d?.id ?? i;

            return (
              <FootableRow
                key={_key}
                index={_key}
                rowFormat={props.options?.rowFormatter?.(d)}
                // footChild={tempArray.map((item: any)=>  <tr><td>{item?.displayID}</td></tr>)}
                // expanded={!!d?.controlsList?.items}
              >
                {props.formatting
                  .filter((d) => d.id !== "rowFormat")
                  .map((f, fi) => {
                    const itemIndex = (currentPage - 1) * PageSize + i;
                    const item = dataFilter[itemIndex];
                    return (
                      <td
                        className={`${f.className || ""} ${
                          f.dynamicClassName?.(d) || ""
                        }`}
                        style={{ verticalAlign: "top", ...f.style }}
                        key={fi}
                        title={item?.tooltip}
                        data-title={f.name}
                      >
                        {f.tableDisplayFormat?.(item, itemIndex) ||
                          f.displayFormat?.(item, itemIndex) ||
                          f.format?.(item, itemIndex) ||
                          ""}
                      </td>
                    );
                  })}
              </FootableRow>
            );
          })}
          <tr>
            <td colSpan={12}>
              <Pagination
                total={props.data.length}
                current={currentPage}
                pageSize={PageSize}
                onChange={(e) => handlePageChange(e)}
                showTitle={false}
              />
            </td>
          </tr>
        </>
      ) : (
        <tr style={{ textAlign: "center" as const }}>
          <td colSpan={7}>
            <h3 style={{ color: "#676a6c" }}>No data found</h3>
          </td>
        </tr>
      )}
    </FootableTable>
  );
};
