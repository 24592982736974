import React from "react";
import { Card, Table } from "react-bootstrap";
import * as d3 from "d3";
import _ from "lodash";

export const sumMagnitudes = (data) => _.map(_.unzip(data), _.sum);

const FairSummaryRow: React.FC<{
  data: number[];
  label: string | any;
  financial?: boolean;
  style?: Object;
}> = ({
  data, label, financial = false, style, 
}) => {
  const format = (financial && d3.format("$.3~s")) || d3.format(".3~f");
  return (
    <tr style={style}>
      <td>
        <label>
{label}
        </label>
      </td>
      <td data-title="Minimum	">{format(d3.min(data ?? 0) ?? 0)}</td>
      <td data-title="Mean">{format(d3.mean(data ?? 0) ?? 0)}</td>
      <td data-title="Median">{format(d3.median(data ?? 0) ?? 0)}</td>
      <td data-title="Maximum">{format(d3.max(data ?? 0) ?? 0)}</td>
    </tr>
  );
};

export const ScenarioFairSummaryTable = ({
  data,
  header = "Summary of Simulation Results",
}) => {
  const { magnitudes } = data;
  const frequency = data.frequency?.[0]?.data;

  return (
    <Card>
      <Card.Header>{header}</Card.Header>
      <Card.Body>
        {(frequency || magnitudes) && (
          <div id="no-more-tables" className="table-responsive">
            <Table className=" table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "20%" }} />
                  <th style={{ width: "20%" }}>Minimum</th>
                  <th style={{ width: "20%" }}>Mean</th>
                  <th style={{ width: "20%" }}>Median</th>
                  <th style={{ width: "20%" }}>Maximum</th>
                </tr>
              </thead>
              <tbody>
                {frequency && (
                  <FairSummaryRow
                    label={
                      <span style={{ fontWeight: "bold" }}>Frequency</span>
                    }
                    style={{
                      borderBottom: "solid 2px black",
                    }}
                    data={frequency}
                  />
                )}
                {magnitudes?.length > 0 && (
                  <>
                    <tr>
                      <td colSpan={5} style={{ fontWeight: "bold" }}>
                        <label>Magnitudes</label>
                      </td>
                    </tr>
                    {magnitudes?.map((magnitude) => (
                      <FairSummaryRow
                        financial
                        data={magnitude.data}
                        label={magnitude.title}
                      />
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
