import { usePermissions } from "common/Permissions";
import React, { useState } from "react";
import {
  Row, Col, Breadcrumb, Card, Modal, Button
} from "react-bootstrap";
import useSetTitle from "utils/useSetTitle";
import { sleep } from "utils/useSleep";
import { HGForm, HGFormSubmit, HGFormControl } from "common/HGForm";
import { createOrganization } from "graphql/mutations";
import { CreateOrganizationMutation, CreateOrganizationMutationVariables } from "API";
import { useMutation, ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { cancelPopupMessage } from "utils/globalVariables";


const CreateSystemAdmin: React.FC = () => {
  const {  refetchOrganizations } = usePermissions();
  const [show, setShow] = useState(false);

  const [runModifyUserPrefs] = useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(gql(createOrganization), {
    onCompleted: (response) => {
      ToastifyQueue('Organization Created Successfully.', 'success');
       sleep(500).then(refetchOrganizations);
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, 'danger');
    },
  });
  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShow(false);
    }
  }
  return (
    <>
      <Button
        variant="outline-primary"
        onClick={() => {
          setShow(true);
        }}
      >
        Create Organization
      </Button>
      <Modal
        show={show}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async (input) => {
            return runModifyUserPrefs({
              variables: {
                input:
                {
                  ...input
                },
              },
            }).then(async (response) => {
              await refetchOrganizations();
              return setShow(false);
            });
          }}
        >
          <Modal.Header>Create Organization</Modal.Header>
          <Modal.Body>
            <HGFormControl name="name" label="Name" type="text" required />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn cancel-btn"
              style={{ marginRight: "5px" }}
              data-dismiss="modal"
              onClick={() => {
                Confirmation();
              }}
            >
              Cancel
            </button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
export const SystemSettings = () => {
  useSetTitle("System Settings");
  const { organization, organizations } = usePermissions();
  return (
    <>
      <Row className="dashboard-header">
        <Col xs="12">
          <Breadcrumb>
            <Breadcrumb.Item>System Settings</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs="12">
          <h3> System Settings</h3>
        </Col>
      </Row>
      <Row>
        {
          (organization?.userRole === "admin" && (
            <Col sm={12}>
              <Card>
                <Card.Header>
                  Organizations
                  <span className="float-right">
                    <CreateSystemAdmin />
                  </span>
                </Card.Header>
                <Card.Body>
                  {
                    organizations.length > 1 ? (
                      organizations && organizations.map((org) => (
                        <li>{org.title}</li>
                      ))) : <li>{organizations[0]?.title}</li>
                  }
                </Card.Body>
              </Card>
            </Col>
          ))
        }
      </Row>
    </>
  );
};
