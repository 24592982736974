import React, { useState, useEffect, useCallback } from "react";
import {
  faBars,
  faCog,
  faSignOutAlt,
  faEnvelope,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePermissions } from "common/Permissions";
import { useAuthState } from "utils/AuthState";
import { Dropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "@aws-amplify/auth";
import { useMutation, gql } from "@apollo/client";
import { Notifications } from "../../../Modules/Customers/Dashboard/Panels/Notifications";
import { modifyNotification } from "graphql/mutations";
import {
  ModifyNotificationMutation,
  ModifyNotificationMutationVariables,
} from "API";
import { useGetOwnedNotifications } from "utils/connectors/notificationConnectors";
import { useCollapsibleContext } from "../LeftNavBar/CollapsibleContext";
import { notificationInterval } from "utils/globalVariables";
import "./Header.scss";
import { GroupSelector } from "./GroupSelector";

function Header() {
  const { collapsed, toggleCollapsed } = useCollapsibleContext();
  const permissions = usePermissions();
  const groups = permissions.groups.sort((a, b) =>
    a.title.localeCompare(b.title)
  );
  const authState = useAuthState();
  const { userAttributes } = authState;

  const [runModifyNotification] = useMutation<
    ModifyNotificationMutation,
    ModifyNotificationMutationVariables
  >(gql(modifyNotification));
  const { notifications, refetch } = useGetOwnedNotifications(10);
  const [showNotification, setShowNotification] = useState(true);

  const fetchNotification = useCallback(() => {
    setShowNotification(true);
  }, [notifications]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
      notifications.forEach((notification) => {
        if (notification?.isRead === false) {
          fetchNotification();
        }
      });
    }, notificationInterval);

    return () => clearInterval(interval);
  });
  const readNotification: any[] = [];
  const parentRiskDomain = [{ id: "", title: "" }];

  notifications.forEach(function (element) {
    if (element?.isRead === false) {
      return readNotification.push({ id: element?.id, isRead: true });
    }
  });
  const updateStatus = () => {
    return runModifyNotification({
      variables: {
        input: readNotification,
      },
    }).then(async (response) => {
      if (response?.data) {
        setShowNotification(false);
      }
    });
  };
  return (
    <div className="row border-bottom">
      <nav
        className="navbar navbar-static-top white-bg"
        role="navigation"
        style={{
          position: "fixed",
          marginBottom: "0px",
          zIndex: 999,
          width: "100%",
          paddingRight: collapsed ? "80px" : "230px",
        }}
      >
        <ul className="left-nav">
          <li className="navbar-header">
            <button
              type="button"
              aria-label="collapsed"
              className=" minimalize-styl-2 btn btn-primary "
              onClick={toggleCollapsed}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </li>
          <li>
            {permissions.organizations.length === 1 && (
              <h4 className="organization-name">
                {permissions.organizations[0].title}
              </h4>
            )}
          </li>
          {userAttributes && (
            <>
              <li
                data-testid="navitem"
                style={{ textAlign: "center", marginBottom: "10px" }}
              >
                {permissions.organizations.length > 1 && (
                  <Dropdown
                    className="organization-element"
                    id="organization-dropdown"
                  >
                    <Dropdown.Toggle id="organization-dropdown">
                      <div style={{ float: "right" }}>
                        <b className="caret" />
                      </div>
                      {permissions.organization.title}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="right" style={{ zIndex: 100000 }}>
                      {permissions.organizations.map((org) => (
                        <Dropdown.Item
                          active={permissions.organization.id === org.id}
                          onClick={(): void => {
                            permissions.setOrganizationId(org.id);
                          }}
                          style={{
                            minHeight: "auto",
                            padding: "0.25rem 1.5rem",
                          }}
                        >
                          {org.title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </li>
              <li
                data-testid="navitem"
                style={{ textAlign: "center", marginBottom: "10px" }}
              >
                <GroupSelector
                  onChange={(_id): void => {
                    permissions.setGroupId(_id);
                  }}
                  isAttachment={false}
                  groupID={permissions.group.id}
                />
              </li>
            </>
          )}
        </ul>
        <ul className="nav navbar-top-links navbar-right">
          <li className="dropdown">
            <a
              className="dropdown-toggle count-info"
              data-toggle="dropdown"
              href="#"
              aria-label="Notification"
            >
              {notifications.map((notification) =>
                showNotification === true && notification?.isRead === false ? (
                  <span className="notifonline" />
                ) : (
                  ""
                )
              )}
              <Dropdown className="profile-element " id="notification">
                <Dropdown.Toggle id="notification">
                  <FontAwesomeIcon
                    className="mr-1"
                    icon={faBell}
                    aria-hidden="true"
                    onClick={updateStatus}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" style={{ zIndex: 100000 }}>
                  <Dropdown.Item
                    data-test="sign-out-button"
                    style={{ minHeight: "auto" }}
                  >
                    <Notifications />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </a>
            <ul className="dropdown-menu dropdown-alerts">
              <li>
                <a href="mailbox.html" className="dropdown-item">
                  <div>
                    <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
                    You have 16 messages
                    <span className="float-right text-muted small">
                      4 minutes ago
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            {userAttributes && (
              <Dropdown className="user-element" id="user-dropdown">
                <Dropdown.Toggle id="user-dropdown">
                  <div style={{ float: "right" }}>
                    <b className="caret" />
                  </div>
                  {permissions.user.displayName}
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" style={{ zIndex: 100000 }}>
                  <LinkContainer to="/settings">
                    <Dropdown.Item>
                      <FontAwesomeIcon className="mr-1" icon={faCog} />
                      Settings
                    </Dropdown.Item>
                  </LinkContainer>
                  <Dropdown.Item
                    onClick={(): void => {
                      Auth.signOut();
                    }}
                    data-test="sign-out-button"
                    style={{ minHeight: "auto" }}
                  >
                    <FontAwesomeIcon className="mr-1" icon={faSignOutAlt} />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
