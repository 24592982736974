import React, { useCallback } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  GetOwnedScenariosQuery,
  GetOwnedScenariosQueryVariables,
  GetScenarioChangesInGroupQuery,
  GetScenarioChangesInGroupQueryVariables,
  GetScenariov3Query,
  GetScenariov3QueryVariables,
  GetScenariosInGroupv3Query,
  GetScenariosInGroupv3QueryVariables,
} from "API";
import { usePermissions } from "common/Permissions";
import {
  getScenariov3,
  getScenariosInGroupv3,
  getScenarioChangesInGroup,
  getOwnedScenarios,
  getAnalysis,
} from "graphql/queries";
import { DateTime } from "luxon";
import { getFairData } from "Modules/Customers/FAIR/Summary/FairSummary";
import { useGroupCheck } from "utils/useGroupCheck";
import {
  useProtectedPolling,
  useProtectedQuery,
} from "utils/useProtectedApollo";
import { sortToID } from "./connectorUtils";
import * as d3 from "d3";
import { sumMagnitudes } from "Modules/Customers/FAIR/Summary/ScenarioFairSummaryTable";
import { useGetGroupByRelatedItem } from "utils/useGetGroupByRelatedItems";
import { getRiskCategories } from "../../Modules/Customers/Risk/RiskPage/RiskTabs/RiskCategories/categories";
import { zipSum } from "utils/stats";
import { BoxPlotSVG, ExceedanceGraphSVG } from "common/fair-graphs";
import { graphToPngUri } from "common/fair-graphs/graphToBase64";
import { formatStatus } from "utils/formatStatus";
import { getCompositeId } from "utils/dataFormatters";
import { getFAIRScopeObject } from "Modules/Customers/Risk/riskReportUtils";

const scenariosInGroupTransform = (response) => {
  return (
    response.data?.getScenariosInGroupv3?.items.map((item) => item.scenario) ||
    []
  )
    .filter((item) => item?.isTemplate !== true)
    .map(sortToID("SCENARIO#"));
};

export const useGetOwnedScenarios = () => {
  const { hasGroup, userId } = usePermissions();

  const response = useProtectedQuery<
    GetOwnedScenariosQuery,
    GetOwnedScenariosQueryVariables
  >(
    gql(getOwnedScenarios),
    {
      variables: {
        id: userId,
      },
    },
    hasGroup
  );

  const scenarios = (response.data?.getOwnedScenarios?.items || [])
    .map(({ scenario }) => scenario)
    .map(sortToID("SCENARIO#"));

  return { ...response, scenarios };
};

export const useGetScenariosInSelectedGroup = () => {
  const [_getScenariosInSelectedGroup, response] = useLazyQuery<
    GetScenariosInGroupv3Query,
    GetScenariosInGroupv3QueryVariables
  >(gql(getScenariosInGroupv3));

  return (id) =>
    _getScenariosInSelectedGroup(id).then(scenariosInGroupTransform);
};

export const useGetScenariosInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetScenariosInGroupv3Query,
    GetScenariosInGroupv3QueryVariables
  >(
    gql(getScenariosInGroupv3),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const scenarios = (
    response.data?.getScenariosInGroupv3?.items.map((item) => item.scenario) ||
    []
  )
    .filter((item) => item?.isTemplate !== true)
    .map(sortToID("SCENARIO#"))
    .map(({ ...scenario }) => ({ ...scenario, relationType: "scenario" }));

  const scenariosTemplates = (
    response.data?.getScenariosInGroupv3?.items.map((item) => item.scenario) ||
    []
  )
    .filter((item) => item?.isTemplate)
    .map(sortToID("SCENARIO#"))
    .map(({ ...scenario }) => ({ ...scenario, relationType: "scenario" }));

  return { ...response, scenarios, scenariosTemplates };
};

export const useGetGroupRiskThreshold = () => {
  const { group, refetchGroups } = usePermissions();

  return {
    ...group,
    refetch: refetchGroups,
    riskThreshold: group?.riskThreshold || undefined,
  };
};

export const useGetScenario = (id, validateGroup = true) => {
  const { group, hasGroup } = usePermissions();
  const response = useProtectedQuery<
    GetScenariov3Query,
    GetScenariov3QueryVariables
  >(
    gql(getScenariov3),
    {
      variables: {
        groupID: group.id,
        id,
      },
    },
    hasGroup
  );

  const scenario = sortToID("SCENARIO#")(response?.data?.getScenariov3);

  useProtectedPolling(scenario, response);

  const canAccess = useGroupCheck(scenario?.groupID, "/risk", validateGroup);

  return { ...response, scenario: canAccess && scenario };
};

export const useGetScenarioChangesInGroup = () => {
  const { group, hasGroup } = usePermissions();

  const response = useProtectedQuery<
    GetScenarioChangesInGroupQuery,
    GetScenarioChangesInGroupQueryVariables
  >(
    gql(getScenarioChangesInGroup),
    {
      variables: {
        id: group.id,
      },
    },
    hasGroup
  );

  const changes = (response.data?.getScenarioChangesInGroup?.items || [])
    .map(sortToID("CHANGELOG#SCENARIO#"))
    .sort(
      (a, b) =>
        DateTime.fromISO(b.createdAt).toMillis() -
        DateTime.fromISO(a.createdAt).toMillis()
    );

  return { ...response, changes };
};

const getPngbase64 = async (item) => {
  return graphToPngUri(item);
};

export const useGetDownloadScenarioObj = (item, files) => {
  const { getOrganizationUser, groups } = usePermissions();
  const { riskCategories } = getRiskCategories();

  const scenarioArray = [
    "title",
    "status",
    "owner",
    "creationDate",
    "lastRevision",
    "nextRevision",
    "indicator",
    "description",
    "assumptions",
    "notes",
    "analysisScope",
    "FAIRSummary",
    "createdAt",
    "lastModified",
    "relatedItems",
    "probabilityOfExceedance",
    "whiskerBoxPlot",
  ];

  const getFairSummaryRowData = (
    data: number[],
    financial: boolean,
    label?: string | null
  ) => {
    const format = (financial && d3.format("$.3~s")) || d3.format(".3~f");

    const minimum = format(d3.min(data ?? 0) ?? 0);
    const mean = format(d3.mean(data ?? 0) ?? 0);
    const median = format(d3.median(data ?? 0) ?? 0);
    const maximum = format(d3.max(data ?? 0) ?? 0);
    return {
      minimum,
      mean,
      median,
      maximum,
    };
  };

  return async (item) => {
    let exceedenceGraph: any = "";
    let boxPlotGraph: any = "";
    const fairData = getFairData(item);

    const frequency = getFairSummaryRowData(
      fairData?.frequency?.[0]?.data,
      true
    );

    const magnitudes = fairData?.magnitudes.map((magnitude) => {
      return {
        [magnitude.title]: getFairSummaryRowData(magnitude?.data, true),
      };
    });

    const baseData = fairData?.magnitudes;

    const totalAggregate =
      (baseData &&
        baseData?.length > 0 && [
          {
            title: "Total",
            data:
              baseData.length === 0
                ? [[]]
                : zipSum(...baseData.map((r) => r.data)),
          },
        ]) ||
      [];

    const dataSet = "magnitudes";
    if (baseData) {
      const ExceedenceGraph = () => (
        <ExceedanceGraphSVG
          height={350}
          width={500}
          data={[...totalAggregate, ...baseData]}
          xFormat={(t) => (dataSet === "magnitudes" && `$${t}`) || t}
          yFormat={(t) => `${t}%`}
          multiple
        />
      );
      const BoxPlot = () => (
        <BoxPlotSVG
          data={baseData}
          yFormat={(t) => (dataSet === "magnitudes" && `$${t}`) || t}
        />
      );
      exceedenceGraph = await getPngbase64(<ExceedenceGraph />);
      boxPlotGraph = await getPngbase64(<BoxPlot />);
    }

    const aggregateMagnitude = getFairSummaryRowData(
      sumMagnitudes(fairData?.magnitudes.map(({ data }) => data)),
      true
    );
    const groupedRelatedItems = useGetGroupByRelatedItem(
      item?.scenarioRelatedItems.items,
      groups
    );

    const fairSummaryTableUI = (label, data) => {
      return `<tr><td colspan="1" rowspan="1">${(
        label.charAt(0).toUpperCase() + label.slice(1)
      )
        .replace(/([A-Z])/g, " $1")
        .trim()}</td>
        <td colspan="1" rowspan="1">${
          label === "frequency" ? data.minimum?.replace("$", "") : data.minimum
        }</td><td colspan="1" rowspan="1">${
        label === "frequency" ? data.mean?.replace("$", "") : data.mean
      }</td><td colspan="1" rowspan="1">${
        label === "frequency" ? data.median?.replace("$", "") : data.median
      }</td><td colspan="1" rowspan="1">${
        label === "frequency" ? data.maximum?.replace("$", "") : data.maximum
      }</td></tr>`;
    };

    const getFairSummaryData = (data) => {
      const theadString = `<table><tbody><tr><th colspan="1" rowspan="1"></th><th colspan="1" rowspan="1">Minimum</th><th colspan="1" rowspan="1">Mean</th><th colspan="1" rowspan="1">Median</th><th colspan="1" rowspan="1">Maximum</th></tr>`;
      const tbodyString = Object.entries(data).map(([key, value]: any) => {
        if (key !== "magnitudes") {
          return fairSummaryTableUI(key, value);
        }
        return (
          value &&
          value?.map((item) => {
            return Object.entries(item).map(([key, value]) => {
              return fairSummaryTableUI(key, value);
            });
          })
        );
      });
      return theadString
        .concat(tbodyString.toString())
        .concat("</tbody></table>");
    };

    if (item) {
      const categories = getFAIRScopeObject(item?.categories);
      const formattedCategoriesArray = riskCategories.map((category) => {
        return {
          [category.title]: category.options
            .filter(({ disabled }) => !disabled)
            .filter(({ value }) =>
              categories?.[category.title]?.includes(value)
            )
            .map(({ label }) => label)
            .reduce((a, b) => (!a ? b : `${a}, ${b}`), ""),
        };
      });

      formattedCategoriesArray.forEach((item, index) => {
        const keys = Object.keys(item);
        if (keys.includes("Impact Categories")) {
          formattedCategoriesArray.push({
            ImpactCategories: item["Impact Categories"],
          });
          formattedCategoriesArray.splice(index, 1);
        }
      });
      formattedCategoriesArray.forEach((item, index) => {
        const keys = Object.keys(item);
        if (keys.includes("Financial Loss")) {
          formattedCategoriesArray.push({
            FinancialLoss: item["Financial Loss"],
          });
          formattedCategoriesArray.splice(index, 1);
        }
      });
      const FAIRSummary = {
        frequency,
        magnitudes,
        aggregateMagnitude,
      };
      const FAIRSummaryTableHtml = getFairSummaryData(FAIRSummary);
      item = {
        ...item,
        title: `${item.title} (${getCompositeId(item)})`,
        status: formatStatus(item?.status),
        analysisScope: formattedCategoriesArray,
        FAIRSummary: FAIRSummaryTableHtml,
        createdAt: DateTime.fromISO(item?.createdAt)
          .toLocaleString(DateTime.DATETIME_MED)
          .replace(/,/g, ""),
        lastModified: DateTime.fromISO(item?.lastModified)
          .toLocaleString(DateTime.DATETIME_MED)
          .replace(/,/g, ""),
        owner: getOrganizationUser(item?.owner)?.displayName,
        relatedItems: groupedRelatedItems || " ",
        probabilityOfExceedance: await exceedenceGraph,
        whiskerBoxPlot: await boxPlotGraph,
      };
      const selectArray = (arr, obj) =>
        arr.reduce((r, e) => Object.assign(r, { [e]: obj[e] }), {});
      return selectArray(scenarioArray, item);
    }
  };
};
