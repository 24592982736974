import React, { useState } from "react";
import { Nav } from "react-bootstrap";

const TabUI =
  (tabList: string[], tabIndex: number, setTabIndex) =>
  ({ children }) => {
    return (
      <div className="tabs-container">
        <Nav fill variant="tabs" style={{ display: "inline-block" }}>
          {tabList.map((tabName, index) => (
            <li className={(tabIndex === index && "active") || "inactive"}>
              <Nav.Link data-toggle="tab" onClick={() => setTabIndex(index)}>
                {tabName}
              </Nav.Link>
            </li>
          ))}
          {children}
        </Nav>
      </div>
    );
  };

export const useTabs = (
  tabList: string[]
): [
  string,
  Function,
  any,
  { increment: Function; decrement: Function; index: number; total: number }
] => {
  const [tabIndex, setTabIndex] = useState(0);

  const tab = tabList[tabIndex];
  const setTab = (tab) => {
    setTabIndex(tabList.findIndex((d) => d === tab));
  };

  const increment = () => {
    setTabIndex((t) => t + 1);
  };
  const decrement = () => {
    setTabIndex((t) => t - 1);
  };

  return [
    tab,
    setTab,
    TabUI(tabList, tabIndex, setTabIndex),
    {
      increment,
      decrement,
      index: tabIndex,
      total: tabList.length,
    },
  ];
};
